@import "minima";

@font-face {
  font-family: "Fira Sans";
  src: local("Fira Sans Light"),
      url("/assets/fonts/FiraSans-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Fira Sans Italic";
  src: local("Fira Sans Light Italic"),
      url("/assets/fonts/FiraSans-Lightitalic.woff2") format("woff2");
}

body {
  font: 400 16px/1.5 "Avenir Next", "Fira Sans", BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
